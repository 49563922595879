// export const BASE_URL = window.location.protocol + "//" + window.location.host;
// export const STORY_BASE_URL = (window.location.protocol + "//" + window.location.host).replace('www.','story.');

export const BASE_URL = 'https://www.tesla-won.it.com';
export const STORY_BASE_URL = 'https://story.tesla-won.it.com';


const Endpoint = {
    POST_TO_ADDRESS: '/api/index/address',
    ME: '/user/getUserinfo',
    
    STORY_DETAIL: '/index.php/api/Tiezi/getTiezi',
    STORY_COMMENT_LIST: '/index.php/api/Pinglun/getPinglunlisttiezi',

    UPLOAD: '/api/common/upload',
    ORDER: '/api/index/index',
    ORDER_DETAIL: '/api/index/getOrderInfoByOrderNo',
    ORDER_UPDATE: '/api/index/updateOrder',
    ORDER_STATUS_UPDATE: '/api/index/webhooksfb',
    TRANSLATION: '/api/Urlrelation/getUrlrelationUrl',
    PIXEL: '/api/Urlrelation/getUrlrelationUrl',
    FB_CONVERSION_EVENT: '/api/facebook/EventsAll',
    CUSTOM_EVENT: '/api/Events/addevent',
    SUBMIT_ORDER:'/api/index/peng'
}
export default Endpoint;

export const DEFAULT_PIXEL_ID = '2265839770416929';
export const DEFAULT_FBQ_TOKEN = 'EAAQkAJNepdoBO2ZCwgXiqfzY30ZBMZAsyUFnKTejPsmphpVKdMRvevvIiFInWgoKnlqMZCdRwM4VwTWbZB93poFAuBAQFsDXSebJGZAfqQD2waZBtU0kGRLbnEnoDQOhZAKQG2pJaSjc8pPSDF6E8NO6FZCZCHAyV147hvEINZCNPSNti4zmZBlME0Bl8MoKHea9BBsarAZDZD';
