import ApiEndpointConstants, { DEFAULT_FBQ_TOKEN, DEFAULT_PIXEL_ID } from '../constants/api/ApiEndpointConstants';
import api from './API';

export const submitFBConversionEvent = (eventname, customdata, userdata, event_id = '', orderNu = '') => {
    return api.post(
        `${ApiEndpointConstants.FB_CONVERSION_EVENT}`,
        {eventname, customdata, userdata, event_id, orderNu,
            pixel: DEFAULT_PIXEL_ID,
            fbqToken: DEFAULT_FBQ_TOKEN,
        },
    );
}
